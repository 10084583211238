<template>
  <!-- 添加或修改菜单对话框 -->
    <el-dialog title="商户授权" v-model="visible" append-to-body width="400">
        <el-row :gutter="10">
            <el-col :span="18"><el-input v-model="name_"  :placeholder="$t('inputTit.shuru')" clearable/></el-col>
            <el-col :span="5"><el-button @click="changeName">查询</el-button></el-col>
        </el-row>
        <div class="long_">
            <div v-for='item in adminArray' :key='item.id'>
                <el-checkbox v-model="item.boole_" :label='item.name' @change="updateChange(item)"/>
            </div>
        </div>

        <pageCom :page='page' @sizeChange='sizeChange' @currentChange='currentChange'/>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="visible = false">{{$t('button.close')}}</el-button>
                <!-- <el-button type="primary" @click="dataFormSubmit(dataForm)">{{$t('button.enter')}}</el-button> -->
            </div>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref,defineExpose,reactive } from 'vue'
import Api_ from '@/api/index'
import pageCom from '@/components/pageCom.vue'

const name_ = ref('')
const copyMenuId = ref('')
const adminArray = ref([])
const itemData = ref({})

const visible = ref(false)
const page = reactive({
    total: 0, // 总页数
    currentPage: 1, // 当前页数
    pageSize: 20 // 每页显示多少条
})

const updateChange = (e)=>{
    if(e.boole_){
        let itemData_ = itemData.value;
        itemData_.tenantId = e.id
        itemData_.copyMenuId = itemData_.id
        delete itemData_.id
        itemData.value = itemData_
        Api_.addMenu(itemData.value).then(res=>{
            if(res.data){
                getList()
            }
        })
    }else{
        Api_.delMenu(e.menuId).then(res=>{
            if(res.data){
                getList()
            }
        })
    }
}
const init = (item)=>{
    visible.value = true
    copyMenuId.value = item.id
    itemData.value = item
    getList()
}
const setData = (arr)=>{
    for(let i = 0;i < arr.length;++ i){
        arr[i].boole_ = arr[i].menuId?true:false
    }
    return arr
}
const changeName = ()=>{
    getList({name:name_.value})
}
const getList = (str)=>{
    let obj_ = {}
    obj_.current = page.currentPage
    obj_.size = page.pageSize
    obj_.copyMenuId = copyMenuId.value
    Api_.shopListAdmin({...obj_,...str}).then(res=>{
        adminArray.value = setData(res.data.records)
    })
}
const sizeChange = (pageSize)=>{ 
    page.pageSize = pageSize;
    getList()
}
const currentChange = (currentPage)=>{ 
    page.currentPage = currentPage;
    getList()
}
defineExpose({
    init
})
</script>
<style lang="scss" scoped>
    .long_{
        max-height: 300px;
        overflow: auto;
        &::-webkit-scrollbar { width: 0 !important }
        margin: 10px 0;
    }
</style>
